<template>
  <div class="jobApp-container">
    <div id="jobApp">
      <!-- Header Section -->
      <div class="header-section">
        <div class="content">
          <div class="text-content">
            <h1>Зарабатывай директором супермаркета <br />с «Перекрёстком»!</h1>
            <button class="apply-btn" @click="scrollToForm">Отликнуться</button>
          </div>
          <div class="header-image">
            <img
              src="/Two.png"
              alt="Supermarket Image"
            />
          </div>
        </div>
        <!-- Logo on the top right, aligned with the right edge of the content -->
        <div class="logo-container">
          <img
            class="logo"
            src="/Logo_X5_Perek_white.png"
            alt="X5 Perekrestok Logo"
          />
        </div>
      </div>

      <modal :adaptive="true" name="modal" height="auto">
        <div class="thanks-modal flex">
          <div style="font-weight: 300" v-if="Date.now() > 1735419600000 && Date.now() < 1736024400000">
            <p style="font-weight: 600">
              Благодарим за отклик!
            </p>
            <br />
            Сейчас колл-центр на каникулах — перезвоним <br>5 января, как только доедим оливье.
            <br />
            <br />
            Пускай Новый 2025 год станет началом нашей крепкой дружбы.
            <br />
            <br />
            Счастливых праздников!
          </div>

          <div v-else>
            Ваш отклик отправлен!
          </div>
        </div>
      </modal>
      <!-- Form Section -->
      <div class="form-section section-outline" id="form">
        <div class="section-circles"></div>
        <h2>
          Заполни анкету<br /><small
            >и мы рассмотрим ее в ближайшее время</small
          >
        </h2>
        <form @submit.prevent="submitForm">
          <div class="form-block">
            <div class="form-group">
              <label for="name">Полное имя</label>
              <span class="form__error-txt __relative" v-show="errors.has('fio')">{{ errors.first('fio') }}</span>
              <input
                type="text"
                id="fio"
                name="fio"
                v-model="form.fio"
                placeholder="Фамилия, Имя, Отчество"
                required
                v-validate="'required|nameRu|fullNameRu'"
                data-vv-validate-on="blur"
              />
            </div>
            <div class="form-group city-input">
              <label for="city">Населенный пункт</label>
              <span class="form__error-txt" v-show="errors.has('city')">{{ errors.first('city') }}</span>
              <input
                type="text"
                id="city"
                name="city"
                v-model="form.city"
                @input="filterCities"
                placeholder="Введи населенный пункт"
                list="city-options"
                v-validate="'required'"
                required
              />
              <datalist id="city-options">
                <option
                  v-for="city in filteredCities"
                  :key="city"
                  :value="city"
                >
                  {{ city }}
                </option>
              </datalist>
            </div>
          </div>
          <div class="form-block">
            <div class="form-group">
              <label for="phone">Телефон</label>
              <span class="form__error-txt __relative" v-show="errors.has('phone')">{{ errors.first('phone') }}</span>
              <input
                type="tel"
                id="phone"
                v-model="form.phone"
                placeholder="+7**********"
                required
                v-validate="'required|tel'"
                v-mask="'+7(###) ###-##-##'"
              />
            </div>
            <div class="form-group">
              <label for="careerObjective">Желаемая должность</label>
              <select id="careerObjective" v-model="form.careerObjective" v-validate="'required'" required>
                <option v-for="vacancy in getStoreDirectorVacancies()" :value="vacancy.value">
                {{vacancy.title}}
              </option>
              </select>
            </div>
          </div>
          <div class="form-block">
            <!-- Citizenship form on the left -->
            <div class="form-group-citizenship">
              <label for="nationality">Гражданство</label>
              <select id="nationality" v-model="form.nationality" required>
                <option disabled selected value="">Выбери гражданство</option>
                <option>Российская Федерация</option>
                <option>Беларусь</option>
                <option>Украина</option>
                <option>Казахстан</option>
                <option>Киргизия</option>
                <option>Узбекистан</option>
                <option>Другое</option>
              </select>
            </div>

            <!-- Checkbox with terms on the right -->
            <div class="terms-checkbox">
              <input
                type="checkbox"
                id="agreed"
                v-model="form.agreed"
                required
              />
              <label for="terms">
                Я соглашаюсь и принимаю
                <a href="/pdf/pdn.pdf" target="_blank">
                  Условия Соглашения об оказании услуг по содействию в
                  трудоустройстве и иных вида занятости</a
                >, а так же даю
                <a href="/pdf/soglasie-pd.docx" target="_blank"
                  >Согласие на обработку персональных данных.</a
                >
              </label>
            </div>
          </div>
          <button type="submit" class="submit-btn" :disabled="disabled">
            <span>Откликнуться</span>
            <div class="loader" :class="{loader_active: !!disabled}" />
          </button>
        </form>
      </div>
      <!-- Leadership Section with Vue Carousel -->
      <div class="leadership-section section-outline">
        <div class="section-circles"></div>
        <carousel
          :per-page="1"
          :loop="true"
          :autoplay="true"
          :autoplay-timeout="5000"
        >
          <!-- Slide 1 -->
          <slide>
            <div class="leadership-content">
              <div class="leadership-image">
                <img
                  src="/slide1.jpg"
                  alt="Team Photo"
                />
              </div>
              <div class="leadership-text">
                <h2>Быть в комьюнити лидеров</h2>
                <p>
                  Высокие результаты — станут приглашением в Клуб Директоров,
                  которые руководят супермаркетом <br />по партнёрской модели. Быть в
                  Клубе — значит развиваться, реализовывать амбиции,
                  обмениваться опытом в кругу единомышленников, создавать
                  будущее российского ритейла.
                </p>
              </div>
            </div>
          </slide>

          <!-- Slide 2 -->
          <slide>
            <div class="leadership-content">
              <div class="leadership-image">
                <img
                  src="/slide2.jpg"
                  alt="Team Photo 2"
                />
              </div>
              <div class="leadership-text">
                <h2>В партнёрстве с «Перекрёстком»</h2>
                <p>
                  Управляя супермаркетом, как своим бизнесом, директора
                  «Перекрёстка» работают в крупной компании и пользуются всеми
                  её преимуществами: стабильным доходом, драйвовой командой,
                  обучением и развитием, социальным пакетом, корпоративной
                  культурой и брендом, который знают и любят.
                </p>
              </div>
            </div>
          </slide>
        </carousel>
      </div>

      <!-- Job Descriptions Section -->
      <div class="job-descriptions">
        <div class="job-card">
          <h3>Директор супермаркета</h3>
          <ul>
            <button class="job-btn">Наши предложения</button>
            <li>• Официальное трудоустройство</li>
            <li>• Удобный супермаркет для работы</li>
            <li>• Профессиональное обучение и развитие</li>
            <li>• Партнёрские отношения в управлении</li>
            <li>• Возможность внедрения и оптимизации бизнес-процессов</li>
            <li>• Конкурентная заработная плата и премии</li>
            <li>• ДМС со стоматологией</li>
            <li>• Хорошие скидки для сотрудников</li>
            <li>• Корпоративные мероприятия</li>
            <br />
          </ul>
          <button class="apply-btn" @click="scrollToForm">Отликнуться</button>
        </div>
        <div class="job-card">
          <h3>Лидер торгового зала (Заместитель директора)</h3>
          <ul>
            <button class="job-btn">Наши предложения</button>
            <li>• Официальное трудоустройство</li>
            <li>• Удобный супермаркет для работы</li>
            <li>• Профессиональное обучение и карьерный рост</li>
            <li>• Конкурентная заработная плата и премии</li>
            <li>• Хорошие скидки для сотрудников</li>
            <li>• Корпоративные мероприятия</li>
            <br /><br /><br /><br /><br />
          </ul>
          <button class="apply-btn" @click="scrollToForm">Отликнуться</button>
        </div>
      </div>

      <!-- Employment Stages Section -->
      <div class="employment-stages">
        <h2>Этапы трудоустройства <br /><br /></h2>
        <ul>
          <li>
            <div class="stage-item">
              <span class="stage-circle"></span>
              <!-- Connecting line -->
              <div class="stage-text">
                <div class="stage-title">Интервью с рекрутером</div>
                <div class="stage-description">
                  В течение двух дней после отклика на вакансию позвонит
                  менеджер по подбору, и вы договоритесь об онлайн встрече.
                </div>
              </div>
            </div>
          </li>
          <li>
            <div class="stage-item">
              <span class="stage-circle"></span>
              <!-- Connecting line -->
              <div class="stage-text">
                <div class="stage-title">Тестирование</div>
                <div class="stage-description">
                  Время продемонстрировать потенциал, заполнив опросники для
                  оценки способностей к аналитическому и бизнес-мышлению.
                </div>
              </div>
            </div>
          </li>
          <li>
            <div class="stage-item">
              <span class="stage-circle"></span>
              <!-- Connecting line -->
              <div class="stage-text">
                <div class="stage-title">Знакомство с руководителем</div>
                <div class="stage-description">
                  На встрече с директором кластера и HR бизнес-партнёром
                  поговорите о твоем опыте, управлении супермаркетами, цифрах <br />и
                  показателях.
                </div>
              </div>
            </div>
          </li>
          <li>
            <div class="stage-item">
              <span class="stage-circle"></span>
              <!-- Connecting line -->
              <div class="stage-text">
                <div class="stage-title">Оффер</div>
                <div class="stage-description">
                  В течение двух дней вернёмся с решением. Если оно
                  положительное, пришлём предложение о работе и запланируем дату
                  выхода.
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <!-- Bottom-left logo placement -->
      <div class="bottom-left-logo">
        <img
          src="/Logo_X5_Perek_white.png"
          alt="X5 Perekrestok Logo"
        />
      </div>
    </div>
  </div>
</template>

<script>
// Import Vue Carousel
import { Carousel, Slide } from "vue-carousel";
import axios from "axios";
import {getStoreDirectorVacancies, isDeliveryVacancy, isDriverVacancy} from '@/model/vacancies';
import urlJoin from 'url-join';
import {getSourceFromCompany} from '@/model/company2Source';

export default {
  components: {
    Carousel,
    Slide,
  },
  data() {
    return {
      form: {
        fio: "",
        phone: "",
        city: "",
        nationality: "Российская Федерация",
        careerObjective: "",
        agreed: false,
      },
      cities: [
        "Москва",
        "Санкт-Петербург",
        "Новосибирск",
        "Екатеринбург",
        "Казань",
        "Нижний Новгород",
      ],
      filteredCities: [],
      utmParams: {
        utm_source: "",
        utm_medium: "",
        utm_campaign: "",
      },
      disabled: false,
    };
  },
  mounted() {
    // Extract UTM parameters from the URL
    const urlParams = new URLSearchParams(window.location.search);
    this.utmParams.utm_source = urlParams.get("utm_source") || "";
    this.utmParams.utm_medium = urlParams.get("utm_medium") || "";
    this.utmParams.utm_campaign = urlParams.get("utm_campaign") || "";
  },
  created() {
    this.params = this.$route.query;
  },
  methods: {
    getStoreDirectorVacancies,
    submitForm() {
      if (!this.form.agreed) {
        alert("Вы должны согласиться с условиями для отправки формы.");
        return;
      }

      this.$validator.validateAll().then((result) => {
        if (!result) {
          return
        }
        this.disabled = true;

        // Вызов метрики Yandex Metrika
        ym(92372732, "reachGoal", "lead", {
          pageurl: this.$route.query.page,
          nat: this.nationality,
          loc: this.city,
          vac: this.form.careerObjective.replace(/\&nbsp\;/gi, " "),
        });

        // Вызов метрики Top.Mail.Ru
        var _tmr = window._tmr || (window._tmr = []);
        _tmr.push({ type: "reachGoal", id: 3421596, goal: "Lead" });

        const onSendSuccess = () => {
          this.disabled = false;
          this.form.fio = '';
          this.form.phone = '';
          this.form.city = '';
          this.form.careerObjective = '';
          this.$validator.reset();
          this.form.agreed = false;
          this.$modal.show('modal');
        }

        const onSendFail = () => {
          this.disabled = false;
          alert('Ошибка при отправке формы. Попробуйте позже.');
        }

        const createSkillazCandidate = () => {
          const params = new FormData()
          params.append('fullName', this.form.fio);
          params.append('vacancyId', this.form.careerObjective);
          params.append('city', this.form.city);
          params.append('phone', this.form.phone.replace(/[-()\s]/g, ''));
          params.append('nationality', this.form.nationality);
          params.append('skillazType', 'perekrestok');
          const source = getSourceFromCompany(this.params.utm_campaign);
          if (source) {
            params.append('skillazId', source);
          }

          this.disabled = true;
          axios.post(urlJoin(process.env.VUE_APP_FEEDBACK_URL, '/candidate'), params).then(onSendSuccess).catch(onSendFail)
        }

        createSkillazCandidate();
      });
    },
    validateForm() {
      return (
        this.form.name &&
        this.form.phone &&
        this.form.city &&
        this.form.citizenship &&
        this.form.position &&
        this.form.agreeTerms
      );
    },
    formatPhoneNumber() {
      const phone = this.form.phone.replace(/[^\d+]/g, "").slice(0, 12);
      if (phone.startsWith("+7")) {
        this.form.phone = phone;
      } else {
        this.form.phone = "+7" + phone.slice(1);
      }
    },
    filterCities() {
      const input = this.form.city.toLowerCase();
      this.filteredCities = this.cities.filter((city) =>
        city.toLowerCase().startsWith(input)
      );
    },
    scrollToForm() {
      const formSection = document.getElementById("form");
      formSection.scrollIntoView({ behavior: "smooth" });
    },
  },
};
</script>

<style>
.submit-btn {
  position: relative;
}

.submit-btn:hover .loader_active {
  border-color: white;
  border-top-color: transparent;
}

.loader {
  display: none;
}

.loader_active {
  display: block;
  position: absolute;
  left: 40px;
  top: 12px;
  border: 2px solid black;
  animation: spin 1s linear infinite;
  border-top-color: transparent;
  border-radius: 100px;
  width: 30px;
  height: 30px;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.jobApp-container {
  font-family: "Gilroy", sans-serif;
  background: url("../../public/Background.png");
  background-size: cover; /* Ensure the background covers the whole page */
  color: #f0f0f0;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

.thanks-modal {
  font-weight: 600;
  font-size: 40px;
  text-align: center;
  height: 100%;
  align-items: center;
  justify-content: center;
  color: black;
  padding-top: 20px;
  padding-bottom: 20px;
  min-height: 300px;
}


.form__error-txt {
  position: absolute;
  color: var(--cl-red);
  font-size: 12px;
  bottom: 0;
  left: 0;
}

#jobApp {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 30px;
}

/* Header Section */
.header-section {
  background: transparent;
  padding: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  max-width: 1200px; /* Match the section width */
  margin: 0 auto; /* Center the header like other sections */
  padding-left: 30px; /* Align with the sections' padding */
  padding-right: 30px; /* Align with the sections' padding */
  padding-bottom: 0px; /* Align with the sections' padding */
}

/* Logo Styles */
.logo-container {
  position: absolute;
  top: 0px;
  right: 0px; /* Aligned to the far right */
}

.logo {
  width: 320px;
  height: auto;
}

/* Bottom-left logo */
.bottom-left-logo {
  bottom: 20px;
  left: 30px; /* Aligns with the sections (matching section padding) */
  z-index: 10; /* Ensure it stays on top of other elements */
}

.bottom-left-logo img {
  width: 320px; /* Same size as the top-right logo */
  height: auto;
  padding-top: 50px;
}

.content {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.text-content {
  max-width: 50%;
  text-align: left;
  animation: slide-in 0.5s ease-in-out;
}

@keyframes slide-in {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

.header-image img {
  max-height: 450px;
  border-radius: 10px;
}

.header-section h1 {
  font-size: 3rem;
  margin-bottom: 20px;
}

.apply-btn {
  background-color: transparent;
  color: white;
  border: 2px solid white;
  padding: 15px 30px;
  font-size: 1.2rem;
  border-radius: 50px;
  transition: all 0.3s ease;
  position: relative;
}

.apply-btn:hover {
  background-color: #4caf50;
  box-shadow: 0 0 20px rgba(0, 255, 0, 0.8);
  transform: scale(1.05);
}

.apply-btn::before {
  content: "";
  position: absolute;
  top: -5px;
  bottom: -5px;
  left: -5px;
  right: -5px;
  border: 2px solid rgba(255, 255, 255, 0.7);
  border-radius: 50px;
  opacity: 0;
  transition: all 0.3s ease;
}

.apply-btn:hover::before {
  opacity: 1;
  box-shadow: 0 0 25px rgba(0, 255, 0, 0.7);
}

/* Section White Outlines */
.section-outline {
  background-color: rgba(0, 0, 0, 0.2);
  padding: 30px;
  border-radius: 15px;
  border: 2px solid white;
  margin-bottom: 30px;
  box-shadow: 0 4px 25px rgba(255, 255, 255, 0.2);
}

/* Form Section */
.form-block {
  display: flex;
  justify-content: space-between;
}

.form-block .form-group {
  width: 48%;
  position: relative;
}

.city-input {
  width: calc(48% - 5px); /* Match the position width */
}

.form-group-citizenship {
  width: 30%;
}

input,
select {
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  border: 2px solid #4caf50;
  background: white;
  color: black;
  margin-bottom: 20px;
}

input:focus,
select:focus {
  outline: none;
  box-shadow: 0 0 15px #4caf50;
}

.submit-btn {
  background: transparent;
  border: 2px solid white;
  color: white;
  padding: 15px 30px;
  font-size: 1.2rem;
  border-radius: 50px;
  width: 100%;
  transition: all 0.3s ease;
}

.submit-btn:hover {
  background-color: #4caf50;
  box-shadow: 0 0 20px rgba(0, 255, 0, 0.8);
}

.terms-checkbox {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 20px;
}

.terms-checkbox input[type="checkbox"] {
  margin-right: 10px;
}
.VueCarousel-dot {
  background-color: white; /* Set the dots to white */
}

.VueCarousel-dot--active {
  background-color: white; /* Ensure the active dot is also white */
}

/* Green pill-shaped button in job card */
.job-btn {
  background-color: #4caf50; /* Green background */
  color: white;
  padding: 10px 20px;
  font-size: 1rem;
  border-radius: 30px; /* Make the button pill-shaped */
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
  display: inline-block;
}

.job-btn:hover {
  background-color: #45a049; /* Darker green on hover */
  box-shadow: 0 0 10px rgba(0, 255, 0, 0.7); /* Add glow effect on hover */
}

.terms-checkbox label a {
  color: #4caf50;
  text-decoration: underline;
}

.terms-checkbox label a:hover {
  color: #fff;
}

/* Job Descriptions Section */
.job-descriptions {
  display: flex;
  justify-content: space-between;
  padding: 40px 0;
}

.job-card {
  width: calc(48% - 10px);
  background-color: rgba(0, 0, 0, 0.2);
  padding: 30px;
  text-align: left;
  border-radius: 15px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  position: relative;
  border: 2px solid transparent;
}

.job-card:hover {
  transform: scale(1.05);
  border: 2px solid white;
  box-shadow: 0 0 15px rgba(255, 255, 255, 0.7);
}

.job-card h3 {
  font-size: 1.8rem;
  margin-bottom: 20px;
}

.job-card ul {
  list-style-type: none;
  padding: 0;
}

.job-card li {
  margin: 15px 0;
  font-size: 1.1rem;
  text-indent: -15px;
}

/* Leadership Section */
.leadership-section {
  padding: 20px;
}

.leadership-content {
  display: flex;
  align-items: center;
}

.leadership-image {
  max-width: 50%;
}

.leadership-image img {
  max-width: 100%;
  border-radius: 15px;
}

.leadership-text {
  max-width: 50%;
  text-align: left;
}

/* Employment Stages Section */
.employment-stages ul {
  list-style: none;
  padding: 0;
}

.employment-stages li {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.stage-item {
  display: flex;
  align-items: center;
}

.stage-circle {
  background-color: transparent;
  border: 2px solid white;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-right: 15px;
}

.stage-line {
  width: 3px;
  height: 60px;
  background-color: white;
  margin-left: 10px;
}

.stage-text {
  color: white;
}

.stage-title {
  font-size: 1.4rem;
  color: #fff;
}

.stage-description {
  font-size: 1.1rem;
  color: #ccc;
  margin-top: 5px;
}

/* Add circles at the top-right corner of form and slide sections */
.form-section,
.leadership-section {
  position: relative; /* Ensure the sections are positioned relative to handle the circles */
}

.section-circles {
  position: absolute;
  top: 15px;
  right: 15px;
  display: flex;
  gap: 10px; /* Space between the two circles */
}

.section-circles::before,
.section-circles::after {
  content: "";
  display: block;
  width: 12px; /* Size of the circles */
  height: 12px;
  border: 2px solid white; /* White outline for the circles */
  border-radius: 50%;
  background-color: transparent;
}

.leadership-content {
  display: flex;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.6); /* Darkened background effect */
  padding: 40px 20px 60px 20px; /* Add more padding at the bottom to move the background lower */
  border-radius: 15px; /* Rounded corners */
  margin-top: 20px; /* Adjust the top margin to fine-tune the position */
}

.leadership-image {
  flex: 1;
  margin-right: 20px; /* Adds space between the image and text */
}

.leadership-image img {
  max-width: 100%;
  border-radius: 15px; /* Rounded image corners */
}

.leadership-text {
  flex: 1;
  color: white; /* Text color */
}

.leadership-text h2 {
  margin-bottom: 10px;
}

.leadership-text p {
  font-size: 1.1rem;
  line-height: 1.5;
}

.header-section {
  display: flex;
  justify-content: space-between;
  align-items: flex-end; /* Align content to the bottom */
  position: relative;
}

.header-image img {
  max-width: 100%;
  height: auto;
  margin-bottom: -30px; /* Adjust this value to move the image lower */
}

h2 small {
  font-weight: 300; /* Thinner font weight */
  font-size: 0.8rem; /* Adjust the size if needed */
  color: #ccc; /* Optional: Change the color to make it lighter */
}

label {
  font-weight: 300; /* Thinner font weight */
  font-size: 0.8rem; /* Adjust the size if needed */
  color: #ccc; /* Optional: Change the color to make it lighter */
  display: block;
  margin-bottom: 8px; /* Adjust this value for the desired gap */
  font-weight: 500; /* Optionally adjust the font weight if needed */
}

input,
select {
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  border: 2px solid #4caf50;
  background: white;
  color: black;
  margin-bottom: 20px;
}
/* Submit Button - match size of apply-btn and center */
.submit-btn {
  background-color: white;
  color: #4caf50;
  border: 2px solid #4caf50;
  padding: 15px 30px;
  font-size: 1.2rem;
  border-radius: 50px;
  width: 100%;
  max-width: 300px; /* Adjust this value if needed */
  transition: all 0.3s ease;
  position: relative;
  cursor: pointer;
  display: block;
  margin: 20px auto; /* Center the button with margin */
}

.submit-btn:hover {
  background-color: #4caf50;
  color: white;
  box-shadow: 0 0 20px rgba(0, 255, 0, 0.8);
  transform: scale(1.05);
}

.submit-btn::before {
  content: "";
  position: absolute;
  top: -5px;
  bottom: -5px;
  left: -5px;
  right: -5px;
  border: 2px solid rgba(76, 175, 80, 0.7);
  border-radius: 50px;
  opacity: 0;
  transition: all 0.3s ease;
}

.submit-btn:hover::before {
  opacity: 1;
  box-shadow: 0 0 25px rgba(0, 255, 0, 0.7);
}

/* Citizenship form */
.form-group-citizenship {
  width: 45%; /* Adjust width to your needs */
  display: inline-block;
  vertical-align: top;
}

/* Terms Checkbox */
.terms-checkbox {
  display: inline-block;
  vertical-align: top;
  margin-left: 10px; /* Adjust space between the checkbox and text */
}

.terms-checkbox input[type="checkbox"] {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.checkbox-text {
  font-size: 1rem;
  color: #ffffff; /* White text */
}

.checkbox-text a {
  color: #4caf50; /* Green links */
  text-decoration: underline;
}

.checkbox-text a:hover {
  color: #ffffff; /* White on hover */
}

/* Adjust button size */
.submit-btn {
  background-color: white;
  color: #4caf50;
  border: 2px solid #4caf50;
  padding: 15px 30px;
  font-size: 1.2rem;
  border-radius: 50px;
  width: 100%;
  max-width: 300px; /* Ensure it matches other buttons */
  margin: 20px auto;
}

/* Form block for layout */
.form-block {
  display: flex;
  justify-content: space-between; /* Align items side by side */
  align-items: center; /* Align vertically */
  margin-bottom: 20px; /* Space between form blocks */
}

/* Citizenship form */
.form-group-citizenship {
  width: 48%; /* Adjust width to occupy half of the space */
  display: inline-block;
}

/* Terms and Checkbox on the right */
.terms-checkbox {
  width: 48%; /* Adjust width to occupy the other half of the space */
  display: flex;
  align-items: center;
}

.terms-checkbox input[type="checkbox"] {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.terms-checkbox label {
  font-size: 0.9rem;
  color: #ffffff;
}

.terms-checkbox label a {
  color: #4caf50;
  text-decoration: underline;
}

.terms-checkbox label a:hover {
  color: #ffffff;
}

h2 {
  padding-bottom: 20px;
}

.employment-stages h3 {
  padding-bottom: 20px;
}


.employment-stages h3 {
  font-size: 1.8rem; /* Adjust the size as needed */
  font-weight: bold;
  margin-bottom: 20px; /* Space below the heading */
}

.job-card {
  width: calc(48% - 10px);
  background-color: rgba(0, 0, 0, 0.2); /* 20% transparency */
  padding: 30px;
  text-align: left;
  border-radius: 15px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  position: relative;
  border: 2px solid transparent;
}

.job-card:hover {
  transform: scale(1.05);
  border: 2px solid white;
  box-shadow: 0 0 15px rgba(255, 255, 255, 0.7);
}
/* Adjust circle size for mobile */
@media (max-width: 768px) {
  .section-circles::before,
  .section-circles::after {
    width: 10px;
    height: 10px;
  }
}

/* Responsive Styles */
@media (max-width: 768px) {
  .form-block {
    flex-direction: column;
  }

  .form-block .form-group {
    width: 100%;
  }

  .job-descriptions {
    flex-direction: column;
    align-items: center;
  }

  .job-card {
    width: 100%;
    margin-bottom: 20px;
  }

  .header-section {
    flex-direction: column;
    align-items: center;
  }

  .logo {
    width: 240px;
    height: auto;
    margin: 50px;
  }

  .bottom-left-logo {
    width: 240px;
    height: auto;
    margin: 50px;
  }

  .bottom-left-logo img {
    width: 240px;
    height: auto;
    padding-top: 50px;
  }

  .header-image {
    display: none;
  }

  .header-section h1 {
    font-size: 2rem; /* Smaller h1 size for mobile */
    text-align: center;
  }

  .text-content {
    max-width: 100%;
    text-align: left;
  }

  .header-image img {
    max-width: 100%;
    height: auto;
  }

  .leadership-content {
    flex-direction: column;
    text-align: left;
  }

  .leadership-image,
  .leadership-text {
    max-width: 100%;
    margin-bottom: 20px;
  }
  .header-section {
    flex-direction: column;
    align-items: center;
  }

  .logo-container {
    margin-top: -50px; /* Move the logo upwards */
  }

  .header-section h1 {
    font-size: 2rem; /* Smaller h1 size for mobile */
    text-align: center;
  }

  .header-image img {
    max-width: 100%;
    height: auto;
  }

  /* Center the apply button */
  .apply-btn {
    display: block;
    margin: 20px auto; /* Center with margin auto */
  }
}
</style>
